import { gql } from '@apollo/client'

export const PROFILE_IMAGE_VIEWER_FRAGMENT = gql`
  fragment ProfileImageViewer on AccountsViewer {
    id
    userProfile {
      id
      avatar {
        thumbnailUrl
      }
    }
  }
`

export const MISSING_IMG = '/profiles/avatars/missing.png'
