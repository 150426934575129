import AccountIcon from '@thebeansgroup/ui_icons/account_icon'
import MenuIcon from '@thebeansgroup/ui_icons/menu_icon'

import { getIsLoggedIn } from '@helpers/auth'

import { MISSING_IMG } from './constants'

export const getImgSrc = (data) => {
  const imgSrc = data?.accountsViewer?.userProfile?.avatar?.thumbnailUrl

  if (!imgSrc || imgSrc.includes(MISSING_IMG)) return ''

  return imgSrc
}

export const getIcon = (data) => {
  if (getIsLoggedIn(data)) return AccountIcon

  return MenuIcon
}
