import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { ACTIVE_CONSUMER_GROUPS } from '@constants/consumer_groups'
import { LOGIN_TYPE, SIGNUP_TYPE, DISCOUNT_TYPE } from '@constants/navigation'
import { useCountryCode } from '@hooks/use_country_code'

import { getButtonHref } from './helpers'
import * as styles from './styles'

const HeaderCCGMenuList = ({ type }) => {
  const country = useCountryCode()
  const router = useRouter()

  const renderCCGMenuItems = () => {
    return ACTIVE_CONSUMER_GROUPS.map(({ id, label, icon: Icon }, index) => (
      <li key={id}>
        <a
          css={styles.link}
          href={getButtonHref(country, id, type, router)}
        >
          <span css={styles.text}>
            <Icon />
            {label}
          </span>
        </a>
      </li>
    ))
  }

  return <ul css={styles.headerMenuItems}>{renderCCGMenuItems()}</ul>
}

HeaderCCGMenuList.propTypes = {
  type: PropTypes.oneOf([SIGNUP_TYPE, LOGIN_TYPE, DISCOUNT_TYPE])
}

HeaderCCGMenuList.defaultProps = {
  type: LOGIN_TYPE
}

export default HeaderCCGMenuList
