import { gql } from '@apollo/client'

import { PROFILE_IMAGE_VIEWER_FRAGMENT } from '@components/profile_image/constants'

export const PROFILE_MENU_VIEWER_FRAGMENT = gql`
  fragment ProfileMenuViewer on AccountsViewer {
    ...ProfileImageViewer
  }
  ${PROFILE_IMAGE_VIEWER_FRAGMENT}
`

export const IMG_SIZE = '18px'
